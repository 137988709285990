import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";

require('@/store/subscriber');

Vue.config.productionTip = false;

store.dispatch('auth/attempt', {access_token: localStorage.getItem("token"), expire_timestamp: localStorage.getItem("token_expire")}).then(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount("#app");
});