import apiClient from "../services/apiClient";
import store from './store'
import router from "../router/router";

export default {
    namespaced: true,
    state: {
        access_token: null,
        authenticated: false,
        expire_timestamp: null,
    },

    getters: {
        authenticated(state) {
            return state.authenticated
        },
        accessToken(state) {
            return state.access_token
        },
        expireTimestamp(state) {
            return state.expire_timestamp
        },
    },

    mutations: {
        SET_AUTHENTICATED(state, authenticated) {
            state.authenticated = authenticated;
        },
        SET_ACCESS_TOKEN(state, accessToken) {
            state.access_token = accessToken;
        },
        SET_EXPIRE_TIME_STAMP(state, expireTimestamp) {
            state.expire_timestamp = expireTimestamp;
        },
        RESET_STATE(state) {
            state.access_token = null;
            state.authenticated = false;
            state.expire_timestamp = null;
        }
    },
    actions: {
        async signIn({dispatch, commit}, credentials) {
            return new Promise((resolve, reject) => {
                apiClient.post("auth/login", credentials).then(response => {
                    dispatch('attempt', {access_token: response.data.access_token, expire_timestamp: response.data.expire_timestamp}).then(() => {
                        resolve();
                    });
                }).catch(error => {
                    reject();
                });
            });
        },

        async attempt({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.access_token) {
                    commit('SET_ACCESS_TOKEN', payload.access_token);
                    commit('SET_AUTHENTICATED', true);
                    commit('SET_EXPIRE_TIME_STAMP', payload.expire_timestamp);
                    store.dispatch('user/attemptGetUser').then(reponse => {
                        resolve();
                    }).catch(() => {
                        commit('RESET_STATE');
                        resolve();
                    });
                } else {
                    commit('RESET_STATE');
                    resolve();
                }
            });
        },

        signOut({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.authenticated) {
                    commit('RESET_STATE');

                    return apiClient.post("auth/logout").then(() => {
                        store.dispatch('user/logOutUser');

                        router.push({name: 'login'});

                        resolve();
                    });
                } else {
                    reject();
                }
            });
        },

        refreshToken({dispatch, state}) {
            return new Promise((resolve, reject) => {
                apiClient.post("auth/refresh").then((response) => {
                    dispatch('attempt', {access_token: response.data.access_token, expire_timestamp: response.data.expire_timestamp}).then(() => {
                        resolve();
                    });
                }).catch(() => {
                    reject();
                });
            });
        }
    },
}
