import store from '@/store/store'

export default function auth({next, router}) {
    if (!store.getters['auth/authenticated']) {
        return next({
            name: 'login'
        })
    }

    return next();
}
