import Vue from "vue";
import VueRouter from "vue-router";
import auth from '@/middleware/auth'

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        component: () => import('@/layouts/main.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/pages/login.vue')
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('@/pages/login.vue')
            },
            {
                path: '/registeren',
                name: 'register',
                component: () => import('@/pages/register.vue')
            },
            {
                path: '/wachtwoord-kiezen',
                name: 'createPassword',
                component: () => import('@/pages/createPassword.vue')
            },
            {
                path: '/wachtwoord-vergeten',
                name: 'forgotPassword',
                component: () => import('@/pages/forgotPassword.vue')
            },
            {
                path: '/wachtwoord-instellen',
                name: 'resetPassword',
                component: () => import('@/pages/resetPassword.vue')
            },
            {
                path: '/informatie-aanvragen',
                name: 'informationRequest',
                component: () => import('@/pages/informationRequest.vue')
            },
            {
                path: '/privacy-statement',
                name: 'privacyStatement',
                component: () => import('@/pages/privacyStatement.vue')
            },
            {
                path: '/over-ons',
                name: 'aboutUs',
                component: () => import('@/pages/aboutUs.vue')
            }
        ]
    },
    {
        path: '/dashboard',
        component: () => import('@/layouts/dashboard.vue'),
        children: [
            {
                path: '/',
                name: 'dashboardHome',
                component: () => import('@/pages/dashboard/home.vue'),
                meta: {
                    middleware: auth
                }
            },
            {
                path: 'wijzig-gegevens',
                name: 'changePersonalInformation',
                component: () => import('@/pages/dashboard/changePersonalInformation.vue')
            },
            {
                path: 'ontwikkelen',
                name: 'dashboardDevelopment',
                component: () => import('@/pages/dashboard/development.vue')
            },
        ],
        meta: {
            middleware: auth
        },
    },
    {
        path: '/dashboard/eigen',
        component: () => import('@/layouts/own.vue'),
        children: [
            {
                path: '/',
                name: 'dashboardOwn',
                component: () => import('@/pages/dashboard/own.vue')
            },
            {
                path: 'scan/gedrag-quick-scan',
                name: 'dashboardOwnScanGQS',
                component: () => import('@/pages/dashboard/own/GQS.vue')
            },
            {
                path: 'scan/driver-quick-scan',
                name: 'dashboardOwnScanDQS',
                component: () => import('@/pages/dashboard/own/DQS.vue')
            },
            {
                path: 'scan/talenten-competentie-analyse',
                name: 'dashboardOwnScanTCA',
                component: () => import('@/pages/dashboard/own/TCA.vue')
            },
            {
                path: 'scan/gedrag-team-profiel',
                name: 'dashboardOwnScanGTP',
                component: () => import('@/pages/dashboard/own/GTP.vue')
            },
            {
                path: 'scan/driver-team-profiel',
                name: 'dashboardOwnScanDTP',
                component: () => import('@/pages/dashboard/own/DTP.vue')
            },
            {
                path: 'scan/pcm-profiel',
                name: 'dashboardOwnScanPCM',
                component: () => import('@/pages/dashboard/own/PCM.vue')
            },
            {
                path: 'scan/ego-analyse',
                name: 'dashboardOwnScanEGO',
                component: () => import('@/pages/dashboard/own/EGO.vue')
            },
			{
				path: 'scan/team-functies-quick-scan',
				name: 'dashboardOwnScanTFQS',
				component: () => import('@/pages/dashboard/own/TFQS.vue')
			},
        ],
    },
    {
        path: '/dashboard/wijs',
        component: () => import('@/layouts/wise.vue'),
        children: [
            {
                path: '/',
                name: 'dashboardWise',
                component: () => import('@/pages/dashboard/wise.vue')
            },
        ],
    },
    {
        path: '/dashboard/wijs/individueel-ontwikkel-plan',
        component: () => import('@/layouts/dashboard.vue'),
        children: [
            {
                path: '',
                name: 'dashboardWiseIOPBase',
                component: () => import('@/pages/dashboard/wise/IOPbase.vue')
            },
        ],
    },
    {
        path: '/dashboard/wijs/individueel-ontwikkel-plan',
        component: () => import('@/layouts/IOP.vue'),
        children: [
            {
                path: 'huidige-situatie',
                name: 'dashboardWiseIOPCurrentSituation',
                component: () => import('@/pages/dashboard/wise/IOPCurrentSituation.vue')
            },
            {
                path: 'gewenste-situatie',
                name: 'dashboardWiseIOPDesiredSituation',
                component: () => import('@/pages/dashboard/wise/IOPDisiredSituation.vue')
            },
            {
                path: 'belemmeringen',
                name: 'dashboardWiseIOPBarriers',
                component: () => import('@/pages/dashboard/wise/IOPBarriers.vue')
            },
            {
                path: 'afronden',
                name: 'dashboardWiseIOPEnd',
                component: () => import('@/pages/dashboard/wise/IOPEnd.vue')
            },
        ],
    },
    {
        path: '/dashboard/wijs/team-ontwikkel-plan',
        component: () => import('@/layouts/dashboard.vue'),
        children: [
            {
                path: '',
                name: 'dashboardWiseTOPBase',
                component: () => import('@/pages/dashboard/wise/TOPbase.vue')
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none'
    }
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    document.title = "Humanwise App";

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    return next();
});

export default router;
