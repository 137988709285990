// axios
import axios from 'axios'
import store from "../store/store";

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL
    // You can add your headers here
});

axiosClient.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Catch all 401 responses
    if (error.response.status === 401) {
        store.dispatch('auth/signOut').then(response => {

        }).catch(error => {
            //to make sure this error is not on console.
        });
    }

    return Promise.reject(error.response);
});

export default axiosClient;
