import store from './store'
import apiClient from "../services/apiClient";

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'auth/SET_ACCESS_TOKEN':
            if (mutation.payload) {
                apiClient.defaults.headers.common["Authorization"] = `Bearer ${mutation.payload}`;
                localStorage.setItem('token', mutation.payload)
            } else {
                apiClient.defaults.headers.common["Authorization"] = null;
                localStorage.removeItem('token')
            }

            break;
        case 'auth/SET_EXPIRE_TIME_STAMP':
            if (mutation.payload) {
                localStorage.setItem('token_expire', mutation.payload)
            } else {
                localStorage.removeItem('token_expire')
            }

            break;
    }
});
