class HwColors {
    constructor(props) {
        this.darkBlue = props.darkBlue;
        this.blue = props.blue;
        this.orange = props.orange;
    }
}

export default new HwColors({
    darkBlue: "#004572",
    blue: "#009EE0",
    orange: "#F08A00"
});
