import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "@/plugins/colors";
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: colors.blue,
                secondary: "#FFCDD2",
                accent: colors.orange,
                white: "#ffffff",
                background: colors.darkBlue,
            },
            dark: {
                primary: colors.blue,
                secondary: "#FFCDD2",
                accent: colors.orange,
                white: "#ffffff",
                background:  colors.darkBlue,
                button: colors.orange,
            }
        },
    },
    icons: {
        iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
})
