import apiClient from "../services/apiClient";

export default {
    namespaced: true,
    state: {
        access_token: '',
        initUserRequested: false,
        user: null,
        scan: null,
        document: null,
        teamDocuments: null,
    },
    getters: {
        user(state) {
            return state.user
        },
        scan(state) {
            return state.scan
        },
        document(state) {
            return state.document
        },
		teamDocuments(state) {
			return state.teamDocuments
		}
    },
    mutations: {
        ACCESS_TOKEN(state, accessToken) {
            state.access_token = accessToken;
        },
        SET_INIT_USER(state, requested) {
            state.initUserRequested = requested;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_SCAN(state, scan) {
            state.scan = scan;
        },
		SET_DOCUMENT(state, document) {
            state.document = document;
        },
		SET_TEAM_DOCUMENTS(state, teamDocuments) {
			state.teamDocuments = teamDocuments;
		},
    },
    actions: {
        attemptGetUser({state, commit}) {
            return new Promise((resolve, reject) => {
                if (state.initUserRequested) {
                    resolve();
                }
                commit('SET_INIT_USER', true);

                apiClient.get('client/get').then(res => {
                    commit('SET_USER', res.data);

                    apiClient.get('scan/list').then(res => {
                        commit('SET_SCAN', res.data);

						apiClient.get('document/list').then(res => {
							commit('SET_DOCUMENT', res.data);
							resolve();
						}).catch(error => {
							commit('SET_DOCUMENT', null);
							reject(error);
						});

						apiClient.get('document/team/list').then(res => {
							commit('SET_TEAM_DOCUMENTS', res.data);
							resolve();
						}).catch(error => {
							commit('SET_TEAM_DOCUMENTS', null);
							reject(error);
						});
                    }).catch(error => {
                        commit('SET_SCAN', null);
                        reject(error);
                    });
                }).catch(error => {
                    commit('SET_USER', null);
                    reject(error);
                });
            })
        },
        refreshUser({commit}){
            apiClient.get('client/get').then(res => {
                commit('SET_USER', res.data);
            });

			apiClient.get('document/team/list').then(res => {
				commit('SET_TEAM_DOCUMENTS', res.data);
			}).catch(error => {
				commit('SET_TEAM_DOCUMENTS', null);
			});
        },
        logOutUser({commit}) {
            commit('SET_USER', null);
            commit('SET_SCAN', null);
        },
    }
}
